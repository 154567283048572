import React, { useState, useEffect } from 'react';
import {
  Input,
  Button,
  List,
  Tooltip,
  Form,
  Col,
  Row,
  Divider,
  Select,
  Tag,
  message,
  Descriptions,
  Radio,
  InputNumber,
} from 'antd';
import PatientForm from '../PatientForm';
import PatientList from '../PatientList';
import { useDispatch, useSelector } from 'react-redux';
import {
  onPatientFilterRead,
  onPatientValueChange,
  onSocialWorkRead,
} from '../../../actions';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  SwitcherOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons';
import { addTurnApi, editTurnApi, updateTurnApi } from '../../../api/turn';
import { getAccessTokenApi } from '../../../api/auth';
import { getUsersTypeApi } from '../../../api/user';
import { getServicesApi } from '../../../api/service';
import Modal from '../../Modal';

export default function TurnForm({
  turn,
  turnId,
  turnUser,
  setReload,
  setIsVisibleModal,
  size,
  turnType,
  edit,
  date,
  scheduleConfig,
  type,
  percentTurn,
  services,
}) {
  const [users, setUsers] = useState([]);
  const [fastTurn, setFastTurn] = useState(false);
  const [percentStudie, setPercentStudie] = useState();
  const [servicesData, setServicesData] = useState([]);

  const [isVisibleModalForm, setIsVisibleModalForm] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [isAddingTurn, setIsAddingTurn] = useState(false);

  const dispatch = useDispatch();
  const [turnForm] = Form.useForm();

  const { patients, searchPatient, loading } = useSelector(
    (store) => store.patientData
  );
  const { studies } = useSelector((store) => store.tenantData);
  const { socialWorks } = useSelector((store) => store.socialWorkData);
  const { role } = useSelector((store) => store.userData?.user);
  const userId = useSelector((store) => store.userData?.user?._id);
  const percentStudieConfig = useSelector(
    (store) => store.userData?.user?.scheduleConfig?.percentTurn
  );

  useEffect(() => {
    const getData = async () => {
      const token = await getAccessTokenApi();
      getUsersTypeApi(token, true, 'Medico').then((response) => {
        setUsers(response.users);
      });

      getServicesApi(token).then((response) => {
        setServicesData(response?.services);
      });
    };

    getData();
  }, []);

  useEffect(() => {
    setFastTurn(false);
    turnForm.resetFields();
    type !== 'view' && turnForm.setFieldsValue({ studiesTypes: [{}] });
  }, [turn.hourStart]);

  useEffect(() => {
    searchPatient !== '' &&
      !isVisibleModalForm &&
      dispatch(
        onPatientFilterRead({
          searchPatient,
        })
      );
    if (edit) {
      turnForm.setFieldsValue({
        description: turn?.description,
        price: turn?.price,
        doctorApplicant: turn?.doctorApplicant?._id,
        doctorDerivate: turn?.doctorDerivate?._id,
        studiesTypes: turn?.studies,
        medicalOrder: turn?.medicalOrder,
        socialWork: turn?.socialWork,
      });
    }
  }, [searchPatient, isVisibleModalForm]);

  useEffect(() => {
    fastTurn && dispatch(onSocialWorkRead());
    turnForm.setFieldsValue({ name: searchPatient });
  }, [fastTurn]);

  async function addTurn(patient) {
    try {
      setIsAddingTurn(true);
      const token = await getAccessTokenApi();
      let values = turnForm.getFieldsValue();

      if (turnId) {
        const response = await updateTurnApi({
          token,
          turnId,
          dataTurn: {
            hourStart: turn?.hourStart,
            patient: patient._id,
            socialWork: patient?.socialWork,
            affiliate: patient?.affiliate,
            doctorApplicant: values?.doctorApplicant,
            doctorDerivate: values?.doctorDerivate,
            studies:
              values?.studiesTypes?.length > 0 && values.studiesTypes[0]?.name
                ? values?.studiesTypes
                : [],
            description: values?.description,
            price: values?.price,
            scheduleConfig: scheduleConfig,
            sizeSlot: size,
            percent: values?.percentTurn
              ? values.percentTurn
              : percentStudie || percentTurn,
            medicalOrder: values?.medicalOrder,
          },
        });

        if (response?.code !== 200) {
          message.error(response.message);
        } else {
          message.success(response.message);
          setIsVisibleModal(false);

          setReload(true);
          turnForm.resetFields();
          setIsAddingTurn(false);
        }
      } else {
        const response = await addTurnApi({
          token,
          data: {
            date: date,
            user: turnUser,
            sizeSlot: size,
            dataTurn: {
              hourStart: turn?.hourStart,
              patient: patient._id,
              socialWork: patient?.socialWork,
              affiliate: patient?.affiliate,
              doctorApplicant: values?.doctorApplicant,
              doctorDerivate: values?.doctorDerivate,
              studies:
                values?.studiesTypes?.length > 0 && values.studiesTypes[0]?.name
                  ? values?.studiesTypes
                  : [],
              description: values?.description,
              price: values?.price,
              turnType: turnType,
              percent: values?.percentTurn
                ? values.percentTurn
                : percentStudie || percentTurn,
              medicalOrder: values?.medicalOrder,
            },
          },
        });

        if (response?.code === 500) {
          message.error(response.message);
          setIsAddingTurn(false);
        } else if (response?.code === 404) {
          message.warning(response.message);
          setIsVisibleModal(false);
          setIsAddingTurn(false);

          setReload(true);
        } else {
          message.success(response.message);
          setIsVisibleModal(false);

          setReload(true);
          turnForm.resetFields();
          setIsAddingTurn(false);
        }
      }
    } catch (error) {
      message.error('Error del servidor');
      setIsAddingTurn(false);
    }
  }

  async function addFastTurn() {
    try {
      setIsAddingTurn(true);
      const token = await getAccessTokenApi();
      let values = turnForm.getFieldsValue();

      if (turnId) {
        const response = await updateTurnApi({
          token,
          turnId,
          dataTurn: {
            hourStart: turn?.hourStart,
            socialWork: values?.socialWork,
            name: values.patientName,
            description: values?.description,
            price: values?.price,
            studies:
              values?.studiesTypes?.length > 0 && values.studiesTypes[0]?.name
                ? values?.studiesTypes
                : [],
            scheduleConfig: scheduleConfig,
            sizeSlot: size,
            doctorApplicant: values?.doctorApplicant,
            doctorDerivate: values?.doctorDerivate,
            percent: values?.percentTurn
              ? values.percentTurn
              : percentStudie || percentTurn,
            medicalOrder: values?.medicalOrder,
          },
        });

        if (response?.code === 500) {
          message.error(response.message);
        } else if (response?.code == 404) {
          message.warning(response.message);
          setReload(true);
        } else {
          message.success(response.message);
          setReload(true);
          turnForm.resetFields();
        }
      } else {
        const response = await addTurnApi({
          token,
          data: {
            date: date,
            user: turnUser,
            sizeSlot: size,
            dataTurn: {
              hourStart: turn?.hourStart,
              socialWork: values?.socialWork,
              name: values?.patientName,
              studies:
                values?.studiesTypes?.length > 0 && values.studiesTypes[0]?.name
                  ? values?.studiesTypes
                  : [],
              doctorApplicant: values?.doctorApplicant,
              doctorDerivate: values?.doctorDerivate,
              description: values?.description,
              price: values?.price,
              turnType: turnType,
              percent: values?.percentTurn
                ? values.percentTurn
                : percentStudie || percentTurn,
              medicalOrder: values?.medicalOrder,
            },
          },
        });

        if (response?.code === 500) {
          message.error(response.message);
        } else if (response?.code == 404) {
          message.warning(response.message);
          setReload(true);
        } else {
          message.success(response.message);
          setReload(true);
          turnForm.resetFields();
        }
      }
    } catch (error) {
      message.error('Error del servidor');
    }

    setIsVisibleModal(false);
    setFastTurn(false);
    setIsAddingTurn(false);
  }

  async function editTurn() {
    setIsAddingTurn(true);
    const token = await getAccessTokenApi();
    let values = turnForm.getFieldsValue();

    try {
      const response = await editTurnApi({
        token,
        turnId,
        shiftId: turn?._id,
        dataTurn: {
          doctorApplicant: values?.doctorApplicant,
          doctorDerivate: values?.doctorDerivate,
          studies:
            values?.studiesTypes?.length > 0 && values.studiesTypes[0]?.name
              ? values?.studiesTypes
              : [],
          description: values?.description,
          price: values?.price,
          percent: values?.percentTurn
            ? values.percentTurn
            : percentStudie || percentTurn,
          medicalOrder: values?.medicalOrder,
          socialWork: values?.socialWork,
        },
      });

      if (response?.code !== 200) {
        message.error(response.message);
        setIsAddingTurn(false);
      } else {
        message.success(response.message);
        setReload(true);
        setIsVisibleModal(false);
        turnForm.resetFields();
        setIsAddingTurn(false);
      }
    } catch {
      message.error('Error del servidor');
      setIsAddingTurn(false);
    }
  }

  const medicalOrder = (
    <Col>
      <Form.Item name="medicalOrder" label="Orden Medica">
        <Radio.Group>
          <Radio value={true}>Si</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Form.Item>
    </Col>
  );

  const formServicesList = (
    <Form.List name="studiesTypes">
      {(fields, { add, remove }) => {
        return (
          <div>
            {fields?.map((field, index) => (
              <Row gutter={24} key={index}>
                <Col xs={22} sm={11}>
                  <Form.Item
                    {...field}
                    name={[field.name, 'name']}
                    fieldKey={[field.fieldKey, 'name']}
                    label="Servicio"
                    labelCol={{ span: 24 }}
                  >
                    <Select
                      style={{ width: '100%' }}
                      placeholder="Seleccione estudios o servicios.. (*)"
                      optionFilterProp="children"
                      disabled={turn?.paid}
                      onChange={(value) => {
                        const selectedService = servicesData.find(
                          (service) => service._id === value
                        );
                        const price = selectedService?.price || 0;
                        const name = selectedService?.name || '';

                        const currentStudies =
                          turnForm.getFieldsValue()?.studiesTypes || [];
                        const updatedStudies = {
                          ...currentStudies[field.name],
                          name,
                          price: parseFloat(price),
                        };
                        currentStudies[field.name] = updatedStudies;

                        turnForm.setFieldsValue({
                          studiesTypes: currentStudies,
                        });

                        const totalPrice = currentStudies.reduce(
                          (accumulator, currentValue) =>
                            accumulator +
                            (parseFloat(currentValue?.price) || 0),
                          0
                        );

                        turnForm.setFieldsValue({
                          price: totalPrice,
                        });
                      }}
                    >
                      {servicesData?.map((service) => {
                        if (services?.find((e) => e === service._id)) {
                          return (
                            <Select.Option
                              key={service._id}
                              value={service._id}
                            >
                              {service?.name}
                            </Select.Option>
                          );
                        }
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={22} sm={11}>
                  {turnForm?.getFieldsValue()?.studiesTypes &&
                    turnForm?.getFieldsValue()?.studiesTypes[field?.name]
                      ?.name && (
                      <Form.Item
                        label="Valor"
                        labelCol={{ span: 24 }}
                        {...field}
                        name={[field.name, 'price']}
                        fieldKey={[field.fieldKey, 'price']}
                        initialValue={0}
                      >
                        <Input
                          disabled={turn?.paid}
                          onChange={() => {
                            const currentStudies =
                              turnForm.getFieldsValue()?.studiesTypes || [];
                            const totalPrice = currentStudies.reduce(
                              (accumulator, currentValue) =>
                                accumulator +
                                (parseFloat(currentValue?.price) || 0),
                              0
                            );
                            turnForm.setFieldsValue({
                              price: totalPrice,
                            });
                          }}
                          prefix="$"
                          placeholder="Valor.."
                          type="number"
                          inputMode="numeric"
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    )}
                </Col>

                <Col span={1}>
                  <Tooltip title="Eliminar servicio">
                    <Button
                      disabled={turn?.paid}
                      type="link"
                      size="large"
                      onClick={() => {
                        remove(field.name);
                        const remainingStudies =
                          turnForm.getFieldsValue()?.studiesTypes || [];
                        const totalPrice = remainingStudies.reduce(
                          (accumulator, currentValue) =>
                            accumulator +
                            (parseFloat(currentValue?.price) || 0),
                          0
                        );
                        turnForm.setFieldsValue({
                          price: totalPrice,
                        });
                      }}
                    >
                      <MinusCircleOutlined />
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            ))}
            <Col>
              <Form.Item>
                <Tooltip title="Agregar otro servicio">
                  <Button
                    type="default"
                    shape="circle"
                    disabled={
                      (turnForm.getFieldsValue()?.studiesTypes?.length > 1 &&
                        !turnForm.getFieldsValue()?.studiesTypes[
                          turnForm.getFieldsValue()?.studiesTypes?.length - 1
                        ]?.name) ||
                      turn?.paid
                    }
                    onClick={() => {
                      add();
                    }}
                    size="large"
                  >
                    <PlusOutlined />
                  </Button>
                </Tooltip>
              </Form.Item>
            </Col>
          </div>
        );
      }}
    </Form.List>
  );

  const addPatient = () => {
    setIsVisibleModalForm(true);
    setModalTitle('Nuevo Paciente');
    setModalContent(<PatientForm setIsVisibleModal={setIsVisibleModalForm} />);
  };

  const socialWorkItem = (
    <Form.Item
      name="socialWork"
      label="Obra social del turno"
      labelCol={{ span: 24 }}
    >
      <Select placeholder="Obra Social" showSearch optionFilterProp="children">
        {socialWorks &&
          socialWorks.map((socialWork, index) => (
            <Select.Option
              key={index}
              value={socialWork.name}
              style={{ textTransform: 'uppercase' }}
            >
              {socialWork.name}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );

  const formTurn = (
    <Form form={turnForm}>
      <Row gutter={24}>
        {!edit &&
          (fastTurn ? (
            <Col xs={24} sm={12}>
              <Form.Item
                name="patientName"
                rules={[
                  {
                    required: true,
                    message: 'Debe cargar el Apellido y Nombre',
                  },
                ]}
                label="Apellido y Nombre"
                labelCol={{ span: 24 }}
                help={medicalOrder}
              >
                <Input
                  placeholder="Apellido Nombre"
                  style={{ textTransform: 'capitalize' }}
                  suffix={
                    <Tooltip title="Volver atras a busqueda de pacientes normal">
                      <Button type="link" onClick={() => setFastTurn(false)}>
                        <SwitcherOutlined />
                      </Button>
                    </Tooltip>
                  }
                />
              </Form.Item>
              {socialWorkItem}
            </Col>
          ) : (
            <Col xs={24} sm={12}>
              <Form.Item
                name="search"
                label="Buscar Paciente"
                labelCol={{ span: 24 }}
                help={medicalOrder}
              >
                <Input.Search
                  enterButton
                  placeholder="Buscar paciente.."
                  onSearch={(value) => {
                    dispatch(onPatientValueChange({ searchPatient: value }));
                  }}
                  allowClear
                  defaultValue={searchPatient}
                  loading={loading}
                  addonAfter={
                    <>
                      <Tooltip title="Nuevo Paciente">
                        <Button type="link" onClick={() => addPatient()}>
                          <PlusOutlined />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Dar turno rapido sin asociar a paciente">
                        <Button type="link" onClick={() => setFastTurn(true)}>
                          <ThunderboltOutlined />
                        </Button>
                      </Tooltip>
                    </>
                  }
                />
              </Form.Item>
            </Col>
          ))}
        {edit && medicalOrder}

        <Col xs={24} sm={8}>
          <Form.Item
            name="description"
            label="Observaciones"
            labelCol={{ span: 24 }}
          >
            <Input.TextArea
              placeholder="Observacion.."
              allowClear
              autoSize={{ minRows: 1 }}
            />
          </Form.Item>
        </Col>
        {(role === 2 ||
          role === 0 ||
          (percentTurn === 100 && turnType !== 'studie') ||
          (percentStudieConfig === 100 && turnType === 'studie')) && (
          <Tooltip
            title={
              services?.length > 0 && 'Indicar el precio al elegir el servicio'
            }
          >
            <Col xs={24} sm={4}>
              <Form.Item
                name="price"
                label="Valor Total"
                labelCol={{ span: 24 }}
                help={
                  ((turnType !== 'studie' && percentTurn !== 100) ||
                    (turnType === 'studie' &&
                      turnForm.getFieldsValue().doctorDerivate !=
                        undefined)) && (
                    <Form.Item name="percentTurn" help="Porcentaje a médico">
                      <InputNumber
                        style={{ marginTop: '5px' }}
                        type="number"
                        max={100}
                        min={0}
                        defaultValue={
                          (turnType !== 'studie' || !turnId) && percentTurn
                        }
                        inputMode="numeric"
                        addonAfter="%"
                      />
                    </Form.Item>
                  )
                }
              >
                <Input
                  allowClear
                  type="number"
                  inputMode="numeric"
                  prefix="$"
                  disabled={(services?.length > 0 && true) || turn?.paid}
                />
              </Form.Item>
            </Col>
          </Tooltip>
        )}
      </Row>
      <Row gutter={24}>
        {turnType === 'studie' ? (
          <>
            <Col xs={12} sm={6}>
              <Form.Item
                name="doctorApplicant"
                label="Medico Solicitante"
                labelCol={{ span: 24 }}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Seleccione medico solicitante.. (*)"
                  optionFilterProp="children"
                  allowClear
                >
                  {users?.map((user, index) => (
                    <Select.Option key={index} value={user._id}>
                      {user.name} {user.lastname}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Item
                name="doctorDerivate"
                label="Medico Efector"
                labelCol={{ span: 24 }}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Seleccione medico efector.. (*)"
                  optionFilterProp="children"
                  allowClear
                  onChange={(value, options) => {
                    turnForm.setFieldsValue({
                      percentTurn: parseInt(options?.key) || 100,
                    });
                    setPercentStudie(parseInt(options?.key) || 100);
                  }}
                >
                  {users?.map((user, index) => {
                    if (
                      user?.viewStudies?.some((e) => e === turnUser) ||
                      (user.role === 2 && user.type === 'Medico') ||
                      studies.some(
                        (studie) =>
                          studie.id === turnUser && studie.view === true
                      )
                    ) {
                      return (
                        <Select.Option
                          key={user?.scheduleConfig?.percent}
                          value={user._id}
                        >
                          {user.name} {user.lastname}
                        </Select.Option>
                      );
                    }
                  })}
                </Select>
              </Form.Item>
            </Col>
            {services?.length > 0 && (
              <Col xs={24} sm={12}>
                {formServicesList}
              </Col>
            )}
          </>
        ) : (
          services?.length > 0 && <Col span={24}>{formServicesList}</Col>
        )}
      </Row>
      {edit && (
        <>
          <Col>{socialWorkItem}</Col>
          <Col xs={24}>
            <Form.Item>
              <Tooltip title="Actualizar">
                <Button
                  style={{ width: '100%' }}
                  type="default"
                  onClick={() => editTurn()}
                  disabled={isAddingTurn}
                  loading={isAddingTurn}
                >
                  Actualizar
                </Button>
              </Tooltip>
            </Form.Item>
            <Divider>Datos del Turno</Divider>
          </Col>
        </>
      )}
    </Form>
  );

  const viewDetailTurn = (
    <Col>
      <Descriptions column={{ xs: 1, sm: 1, md: 2, lg: 3 }}>
        {(role === 2 || role === 0) && (
          <>
            <Descriptions.Item label="Valor total">
              {turn.price?.toLocaleString('es-AR', {
                style: 'currency',
                currency: 'ARS',
                minimumFractionDigits: 2,
              }) || ''}
            </Descriptions.Item>
            <Descriptions.Item label={'Porcentaje a médico'}>
              {turn.percent}%
            </Descriptions.Item>
          </>
        )}
        {(role === 2 ||
          turn?.doctorDerivate?._id === userId ||
          turnType !== 'studie') && (
          <Descriptions.Item label={'Valor médico'}>
            {turn?.price
              ? ((turn?.price * turn?.percent) / 100).toLocaleString('es-AR', {
                  style: 'currency',
                  currency: 'ARS',
                  minimumFractionDigits: 2,
                })
              : ''}
          </Descriptions.Item>
        )}
        {turn?.studies?.length > 0 && (
          <Descriptions.Item label={'Estudios'}>
            <ul>
              {turn?.studies.map((studie) => (
                <li>
                  <Tag>
                    {studie?.name}:{' '}
                    {studie?.price?.toLocaleString('es-AR', {
                      style: 'currency',
                      currency: 'ARS',
                      minimumFractionDigits: 2,
                    })}
                  </Tag>
                </li>
              ))}
            </ul>
          </Descriptions.Item>
        )}
        {turnType === 'studie' && (
          <>
            <Descriptions.Item label={'Medico solicitante'}>
              {turn?.doctorApplicant?.name} {turn?.doctorApplicant?.lastname}
            </Descriptions.Item>
            <Descriptions.Item label={'Medico Efector'}>
              {turn?.doctorDerivate?.name} {turn?.doctorDerivate?.lastname}
            </Descriptions.Item>
          </>
        )}
        <Descriptions.Item label={'Observaciones'}>
          {turn?.description}
        </Descriptions.Item>
        <Descriptions.Item label={'Orden Medica'}>
          {turn?.medicalOrder ? (
            <Tooltip title="Presento orden médica">
              <CheckCircleOutlined style={{ color: 'green' }} />
            </Tooltip>
          ) : (
            <Tooltip title="No presento orden médica">
              <CloseCircleOutlined style={{ color: 'red' }} />
            </Tooltip>
          )}
        </Descriptions.Item>
        <Descriptions.Item label={'Pagado'}>
          {turn?.paid ? (
            <Tooltip title="Pagado">
              <CheckCircleOutlined style={{ color: 'green' }} />
            </Tooltip>
          ) : (
            <Tooltip title="Pendiente">
              <CloseCircleOutlined style={{ color: 'red' }} />
            </Tooltip>
          )}
        </Descriptions.Item>
      </Descriptions>
    </Col>
  );

  return (
    <div>
      {(!turn?.patient || edit) && formTurn}

      {patients && searchPatient !== '' && !fastTurn && (
        <List
          dataSource={patients}
          renderItem={(patient) => (
            <>
              <PatientList
                patient={patient}
                turn={turn}
                turnId={turnId}
                turnUser={turnUser}
                addTurn={(patient) => addTurn(patient)}
                isAddingTurn={isAddingTurn}
              />
              {turn?.patient && viewDetailTurn}
            </>
          )}
          pagination={!turn?.patient && { pageSize: 20, size: 'small' }}
          locale={
            searchPatient
              ? {
                  emptyText: 'No se han encontrado coincidencias...',
                }
              : { emptyText: 'Debe buscar un paciente...' }
          }
        />
      )}

      {fastTurn && (
        <Row gutter={24} style={{ textAlign: 'center' }}>
          <Col span={24}>
            <Button
              type="default"
              style={{ width: '100%', marginTop: '10px' }}
              onClick={() => addFastTurn()}
              disabled={isAddingTurn}
            >
              Asignar turno
            </Button>
          </Col>
        </Row>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModalForm}
        setIsVisible={setIsVisibleModalForm}
        width="60%"
      >
        {modalContent}
      </Modal>
    </div>
  );
}
